var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    [
      _c(
        "v-list-group",
        {
          attrs: { "prepend-icon": "mdi-magnify" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function () {
                return [_c("v-list-item-title", [_vm._v("Recherche")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("x-date-picker", {
                    attrs: { title: "Depuis le ...", required: false },
                    on: {
                      datepicked: _vm.setDateDebut,
                      clean: _vm.setDateDebut,
                    },
                  }),
                  _c("x-date-picker", {
                    attrs: { title: "Jusqu'au ...", required: false },
                    on: { datepicked: _vm.setDateFin, clean: _vm.setDateFin },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }