<template>
  <v-list>
    <v-list-group prepend-icon="mdi-magnify">
      <template v-slot:activator>
        <v-list-item-title>Recherche</v-list-item-title>
      </template>

      <v-card flat>
        <v-card-text>
          <x-date-picker
            title="Depuis le ..."
            :required="false"
            @datepicked="setDateDebut"
            @clean="setDateDebut"
          ></x-date-picker>
          <x-date-picker
            title="Jusqu'au ..."
            :required="false"
            @datepicked="setDateFin"
            @clean="setDateFin"
          ></x-date-picker>
        </v-card-text>
      </v-card>
    </v-list-group>
  </v-list>
</template>

<script>
import XDatePicker from "@/components/time/X-date-picker";
import moment from "moment";

export default {
  name: "X-filtres-poseur",
  components: { XDatePicker },
  data() {
    return {
      query: {},
    };
  },
  methods: {
    setDateDebut(e) {
      if (e) {
        this.query.dateDebut = moment(e).toDate();
      } else {
        delete this.query.dateDebut;
      }
      this.returnQuery();
    },
    setDateFin(e) {
      if (e) {
        this.query.dateFin = moment(e).toDate();
      } else {
        delete this.query.dateFin;
      }
      this.returnQuery();
    },
    returnAllJournees() {
      this.$store.dispatch("time/getJourneesUser");
    },
    returnQuery() {
      if (this.query.dateDebut || this.query.dateFin) {
        this.$store.dispatch("time/returnJourneesFiltres", this.query);
      } else {
        this.returnAllJournees();
      }
    },
  },
};
</script>

<style scoped></style>
