<template>
  <v-container>
    <!--Ma modale d'heures-->
    <x-modale-time
        :visible="modalVisible"
        titre="Nouvel enregistrement"
        subtitle
        :subtitle_text="userInSession.nom + ' ' + userInSession.prenom"
        @close="refresh"
    ></x-modale-time>

    <!--Ma modale de commentaire-->
    <x-modale-commentaire
        :visible="modalCommentaire"
        :commentaire="commentaire"
        @close="showCommentaire"
    ></x-modale-commentaire>

    <!--Mes filtres de recherche-->
    <x-filtres-poseur></x-filtres-poseur>

    <!--Mon bouton de création d'horaire-->
    <v-row justify="center" class="mt-1">
      <v-btn @click="newJournee" width="90%" outlined :color="getTurquoisePoupin" >Nouvel enregistrement <v-icon>mdi-plus-circle-outline</v-icon></v-btn>
    </v-row>

    <!--Ma liste d'horaires-->
    <v-layout row wrap>
      <v-flex xs12 sm12 md6 lg4 xl3 v-for="heure in getJourneeList" :key="heure._id" >
        <x-journee-card
            :heure="heure"
            @show="modalVisible = true"
            @set-commentaire="setCommentaire"
        ></x-journee-card>
      </v-flex>
    </v-layout>

    <!--Composant de consultation des heures des mois précédents-->
    <x-rh-card class="my-1"></x-rh-card>

    <!--Bouton flottant de retour au haut de page-->
    <v-fab-transition mode="out-in">
      <v-btn
          elevation="2"
          v-scroll="onScroll"
          v-show="fab"
          fab
          bottom
          fixed
          right
          dark
          :color="getTurquoisePoupin"
          @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>

  </v-container>
</template>

<script>
import XRhCard from '@/components/time/X-rh-card'
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@/plugins/event-bus';
import _ from 'lodash';
import XModaleTime from "@/components/time/X-modale-time";
import XModaleCommentaire from "@/components/time/X-modale-commentaire";
import XJourneeCard from "@/components/time/X-journee-card";
import XFiltresPoseur from "@/components/time/X-filtres-poseur";

export default {
  name: "XTimeComponent",
  components: {
    XFiltresPoseur,
    XJourneeCard,
    XModaleCommentaire,
    XModaleTime,
    XRhCard
  },
  data() {
    return {
      commentaire: '',
      modalVisible: false,
      modalCommentaire: false,
      fab: false,
      journee: {
        date: moment.utc().startOf("day").toISOString(),
        temps_pause: 60,
        heure_depart: moment().startOf("day").add(7, 'hours').add(30, 'minutes').toISOString(),
        heure_arrivee_premier_chantier: moment().startOf("day").add(8, 'hours').add(30, 'minutes').toISOString(),
        heure_depart_dernier_chantier: moment().startOf("day").add(16, 'hours').add(30, 'minutes').toISOString(),
        heure_arrivee: moment().startOf("day").add(17, 'hours').add(30, 'minutes').toISOString(),
        heure_depart_entreprise: moment().startOf("day").add(18, 'hours').toISOString(),
        lieu_arrivee: 'Domicile',
        lieu_depart: 'Entreprise'
      }
    }
  },
  created() {
    this.getJourneesUser()
  },
  computed: {
    ...mapGetters('time', {
      getJourneeList: 'getJourneeList'
    }),

    ...mapGetters('auth', {
      userInSession: 'userInSession'
    }),

    ...mapGetters(['getTurquoisePoupin'])
  },
  methods: {
    ...mapActions('time', {
      getJourneesUser: 'getJourneesUser'
    }),
    newJournee() {
      this.$store.dispatch('time/setJournee', _.clone(this.journee));
      this.$store.dispatch('time/setResultatVille',[]);
      EventBus.$emit('resetValidation');
      this.modalVisible = true;
    },
    showCommentaire() {
      this.modalCommentaire = !this.modalCommentaire;
    },
    setCommentaire(e) {
      this.commentaire = e;
      this.showCommentaire();
    },
    refresh() {
      this.$store.dispatch('time/getJourneesUser');
      this.modalVisible = false;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 40
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>