<template>
  <x-time-component></x-time-component>
</template>

<script>
import XTimeComponent from "@/components/time/X-time-component";
export default {
  name: "test",
  components: {XTimeComponent}
}
</script>

<style scoped>

</style>