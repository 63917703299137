var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-3", attrs: { "mx-auto": "", "max-width": "500" } },
    [
      _c(
        "v-card-subtitle",
        {
          staticClass: "text-xs-center white--text",
          style: `background-color:${_vm.getTurquoisePoupin}`,
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-icon", { staticClass: "mx-2 white--text" }, [
                _vm._v("mdi-calendar"),
              ]),
              _c("h4", { staticClass: "mx-2" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("fullDate")(
                      _vm.heure.journeeCorrigee
                        ? _vm.heure.journeeCorrigee.date
                        : _vm.heure.date
                    )
                  )
                ),
              ]),
              _c("v-spacer"),
              _c("h4", { staticClass: "mx-2" }, [
                _vm._v(
                  _vm._s(_vm.heure.user.nom) +
                    " " +
                    _vm._s(_vm.heure.user.prenom)
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-simple-table", {
        attrs: { dense: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Horaire "),
                    ]),
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v(" Ma saisie "),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c("th", { staticClass: "text-left" }, [
                          _vm._v(" Correction "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("Lieu de départ")]),
                    _c("td", [_vm._v(_vm._s(_vm.heure.lieu_depart))]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee.lieu_depart !==
                              _vm.heure.lieu_depart
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.heure.journeeCorrigee.lieu_depart)
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Heure de départ")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm._f("simpleTime")(_vm.heure.heure_depart))
                      ),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee.heure_depart !==
                              _vm.heure.heure_depart
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("simpleTime")(
                                  _vm.heure.journeeCorrigee.heure_depart
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Temps de pause")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm._f("humantime")(_vm.heure.temps_pause))
                      ),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee.temps_pause !==
                              _vm.heure.temps_pause
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("humantime")(
                                  _vm.heure.journeeCorrigee.temps_pause
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Dernier chantier")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("simpleTime")(
                            _vm.heure.heure_depart_dernier_chantier
                          )
                        )
                      ),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee
                                .heure_depart_dernier_chantier !==
                              _vm.heure.heure_depart_dernier_chantier
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("simpleTime")(
                                  _vm.heure.journeeCorrigee
                                    .heure_depart_dernier_chantier
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v("Travail sur l'entreprise le soir")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.heure.lieu_arrivee === "Entreprise"
                            ? "Oui"
                            : "Non"
                        )
                      ),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee.lieu_arrivee !==
                              _vm.heure.lieu_arrivee
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.heure.journeeCorrigee.lieu_arrivee ===
                                  "Entreprise"
                                  ? "Oui"
                                  : "Non"
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm.heure.lieu_arrivee === "Entreprise" ||
                  _vm.heure.horaire_retour == true
                    ? _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.heure.lieu_arrivee === "Entreprise"
                                ? "Retour entreprise"
                                : _vm.heure.horaire_retour == true
                                ? "Retour grand déplacement"
                                : ""
                            )
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("simpleTime")(_vm.heure.heure_arrivee)
                            )
                          ),
                        ]),
                        _vm.heure.journeeCorrigee
                          ? _c(
                              "td",
                              {
                                style:
                                  _vm.heure.journeeCorrigee.heure_arrivee !==
                                  _vm.heure.heure_arrivee
                                    ? `color: ${_vm.getOrange}`
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("simpleTime")(
                                      _vm.heure.journeeCorrigee.heure_arrivee
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.heure.lieu_arrivee === "Entreprise"
                    ? _c("tr", [
                        _c("td", [_vm._v("Départ entreprise")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("simpleTime")(
                                _vm.heure.heure_depart_entreprise
                              )
                            )
                          ),
                        ]),
                        _vm.heure.journeeCorrigee
                          ? _c(
                              "td",
                              {
                                style:
                                  _vm.heure.journeeCorrigee
                                    .heure_depart_entreprise !==
                                  _vm.heure.heure_depart_entreprise
                                    ? `color: ${_vm.getOrange}`
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("simpleTime")(
                                      _vm.heure.journeeCorrigee
                                        .heure_depart_entreprise
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", [_vm._v("Total heures")]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("humantime")(
                            _vm.heure.heuresTauxNormal +
                              _vm.heure.heuresMajorees
                          )
                        )
                      ),
                    ]),
                    _vm.heure.journeeCorrigee
                      ? _c(
                          "td",
                          {
                            style:
                              _vm.heure.journeeCorrigee.heuresTauxNormal +
                                _vm.heure.journeeCorrigee.heuresMajorees !==
                              _vm.heure.heuresTauxNormal +
                                _vm.heure.heuresMajorees
                                ? `color: ${_vm.getOrange}`
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("humantime")(
                                  _vm.heure.journeeCorrigee.heuresTauxNormal +
                                    _vm.heure.journeeCorrigee.heuresMajorees
                                )
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-divider"),
      _c(
        "v-card-actions",
        [
          _vm.heure.journeeCorrigee
            ? _c("small", { staticStyle: { color: "indianred" } }, [
                _vm._v("Horaires corrigés"),
              ])
            : _vm.heure.validated
            ? _c("small", { staticStyle: { color: "mediumseagreen" } }, [
                _vm._v("Horaires validés"),
              ])
            : !_vm.heure.validated
            ? _c("small", { staticStyle: { color: "#747171" } }, [
                _vm._v("Horaires en attente de validation"),
              ])
            : _vm._e(),
          _c("v-spacer"),
          _vm.heure.journeeCorrigee
            ? _c(
                "span",
                [
                  _vm.heure.journeeCorrigee.commentaire
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            "x-small": "",
                            outlined: "",
                            color: _vm.getOrange,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setCommentaire(
                                _vm.heure.journeeCorrigee.commentaire
                              )
                            },
                          },
                        },
                        [_vm._v("Commentaire")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: _vm.getTurquoisePoupin,
                disabled: _vm.heure.validated || !!_vm.heure.journeeCorrigee,
                rounded: "",
                text: "",
              },
              on: {
                click: function ($event) {
                  return _vm.modifyTime(_vm.heure)
                },
              },
            },
            [_vm._v("Modifier")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }