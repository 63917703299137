<template>
  <v-card mx-auto max-width="500" class="ma-3 ">
    <v-card-subtitle class="text-xs-center white--text" :style="`background-color:${getTurquoisePoupin}`">
      <v-layout row wrap>
        <v-icon class="mx-2 white--text">mdi-calendar</v-icon>
        <h4 class="mx-2">{{ heure.journeeCorrigee ? heure.journeeCorrigee.date : heure.date | fullDate}}</h4>
        <v-spacer></v-spacer>
        <h4 class="mx-2">{{ heure.user.nom }} {{ heure.user.prenom }}</h4>
      </v-layout>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Horaire
          </th>
          <th class="text-left">
            Ma saisie
          </th>
          <th v-if="heure.journeeCorrigee" class="text-left">
            Correction
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Lieu de départ</td>
          <td>{{ heure.lieu_depart }}</td>
          <td v-if="heure.journeeCorrigee"  :style="heure.journeeCorrigee.lieu_depart !== heure.lieu_depart ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.lieu_depart }}</td>
        </tr>
        <tr>
          <td>Heure de départ</td>
          <td>{{ heure.heure_depart | simpleTime}}</td>
          <td v-if="heure.journeeCorrigee" :style="heure.journeeCorrigee.heure_depart !== heure.heure_depart ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.heure_depart | simpleTime }}</td>
        </tr>
        <tr>
          <td>Temps de pause</td>
          <td>{{ heure.temps_pause | humantime }}</td>
          <td v-if="heure.journeeCorrigee" :style="heure.journeeCorrigee.temps_pause !== heure.temps_pause ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.temps_pause | humantime }}</td>
        </tr>
        <tr>
          <td>Dernier chantier</td>
          <td>{{ heure.heure_depart_dernier_chantier | simpleTime}}</td>
          <td v-if="heure.journeeCorrigee" :style="heure.journeeCorrigee.heure_depart_dernier_chantier !== heure.heure_depart_dernier_chantier ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.heure_depart_dernier_chantier | simpleTime }}</td>
        </tr>
        <tr>
          <td>Travail sur l'entreprise le soir</td>
          <td>{{ heure.lieu_arrivee === 'Entreprise' ? 'Oui' : 'Non' }}</td>
          <td v-if="heure.journeeCorrigee" :style="heure.journeeCorrigee.lieu_arrivee !== heure.lieu_arrivee ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.lieu_arrivee === 'Entreprise' ? 'Oui' : 'Non' }}</td>
        </tr>
        <tr v-if="heure.lieu_arrivee === 'Entreprise' || heure.horaire_retour == true">
          <td>{{ heure.lieu_arrivee === 'Entreprise' ? 'Retour entreprise' : ( heure.horaire_retour == true ? 'Retour grand déplacement' : '') }}</td>
          <td>{{ heure.heure_arrivee | simpleTime}}</td>
          <td v-if="heure.journeeCorrigee"  :style="heure.journeeCorrigee.heure_arrivee !== heure.heure_arrivee ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.heure_arrivee | simpleTime }}</td>
        </tr>
        <tr v-if="heure.lieu_arrivee === 'Entreprise'">
          <td>Départ entreprise</td>
          <td>{{ heure.heure_depart_entreprise | simpleTime}}</td>
          <td v-if="heure.journeeCorrigee"  :style="heure.journeeCorrigee.heure_depart_entreprise !== heure.heure_depart_entreprise ? `color: ${getOrange}` : ''">{{ heure.journeeCorrigee.heure_depart_entreprise | simpleTime }}</td>
        </tr>
        <tr>
          <td>Total heures</td>
          <td>{{ (heure.heuresTauxNormal + heure.heuresMajorees) | humantime }}</td>
          <td v-if="heure.journeeCorrigee" :style="(heure.journeeCorrigee.heuresTauxNormal + heure.journeeCorrigee.heuresMajorees) !== (heure.heuresTauxNormal + heure.heuresMajorees) ? `color: ${getOrange}` : ''">{{ (heure.journeeCorrigee.heuresTauxNormal + heure.journeeCorrigee.heuresMajorees) | humantime }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider></v-divider>
    <v-card-actions>
      <small v-if="heure.journeeCorrigee" style="color: indianred">Horaires corrigés</small>
      <small v-else-if="heure.validated" style="color: mediumseagreen">Horaires validés</small>
      <small v-else-if="!heure.validated" style="color: #747171">Horaires en attente de validation</small>
      <v-spacer></v-spacer>
      <span v-if="heure.journeeCorrigee"><v-btn v-if="heure.journeeCorrigee.commentaire" x-small outlined :color="getOrange" @click="setCommentaire(heure.journeeCorrigee.commentaire)">Commentaire</v-btn></span>
      <v-btn small @click="modifyTime(heure)" :color="getTurquoisePoupin" :disabled="heure.validated || !!heure.journeeCorrigee" rounded text>Modifier</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "X-journee-card",
  props: {
    heure: {
      type: Object
    }
  },
  methods: {
    modifyTime(journee) {
      this.$store.dispatch('time/setJournee', journee);
      this.$store.dispatch('time/setResultatVille',[]);
      this.$emit('show')
    },
    setCommentaire(commentaire) {
      this.$emit('set-commentaire', commentaire);
    }
  },
  computed: {
    ...mapGetters(['getTurquoisePoupin', 'getOrange'])
  },
}
</script>

<style scoped>

</style>