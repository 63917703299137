<template>
  <div>
    <v-card class="mb-1" max-width="800px">
      <v-card-title>
        <span>MOIS PASSES</span>
        <v-spacer></v-spacer>
        <v-btn
            class="blue-grey lighten-2 ma-0 pa-0"
            width="30%"
            dark
            @click="expand = !expand"
        >
          <v-icon v-if="!expand">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

    <v-expand-transition>
      <v-card
          v-show="expand"
          width="100%"
          max-width="800px"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Nom information
              </th>
              <th class="text-left">
                Heures comptabilisées
              </th>
              <!--<th class="text-left">
                Ma saisie
              </th>-->
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Total des heures</td>
              <td>{{ getRh ? getRh.TotalHeures : '' | humantime }}</td>
              <!--<td>{{ getDataMois ? (getDataMois.heureSup + getDataMois.heureNormal) : '' | humantime }}</td>-->
            </tr>
            <tr>
              <td>Heures supplémentaires payées</td>
              <td>{{ getRh ? getRh.HSCumul : '' | humantime }}</td>
              <!--<td></td>-->
            </tr>
            <tr>
              <td>RTT pris</td>
              <td>{{ getRh ? getRh.RTT : 'Pas de données' }}</td>
              <!--<td></td>-->
            </tr>
            <!--<tr>
              <td>RTT restants</td>
              <td>{{ getRh ? getRh.RTTRestant : 'Pas de données' }}</td>
              <td></td>
            </tr>-->
            <tr>
              <td>Tickets restaurants</td>
              <td>{{ getRh ? getRh.TR : 'Pas de données' }}</td>
              <!--<td></td>-->
            </tr>
            <!--<tr>
              <td>Nombre de jours ouvrés</td>
              <td>{{ getRh ? getRh.nbJoursOuvres : 'Pas de données' }}</td>
              <td></td>
            </tr>-->
            <!--<tr>
              <td>Nombre d'enregistrements</td>
              <td></td>
              <td>{{ getDataMois ? getDataMois.nbEnregistrements : '0' }}</td>
            </tr>-->
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="mb-6"></v-divider>
        <v-card-actions class="my-0 py-0">
          <v-select dense outlined label="Mois" :value="query.mois" :items="selectMois" class="mx-2 px-2" @change="setMois"></v-select>
          <v-select dense outlined label="Année" :value="query.annee" :items="selectAnnee" class="mx-2 px-2" @change="setAnnee"></v-select>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "XRhCard",
  data() {
    return {
      selectMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      selectAnnee: [2020, 2021],
      query: { mois: 11, annee: 2020, user: this.$store.getters["auth/userInSession"]._id },
      expand: false
    }
  },
  created() {
    this.returnData();
  },
  methods: {
    setMois(e) {
      this.query.mois = e;
      this.returnData();
    },
    setAnnee(e) {
      this.query.annee = e;
      this.returnData();
    },
    returnData() {
      this.$store.dispatch('rh/returnMoisRh', this.query);
      this.$store.dispatch('time/returnMois', this.query);
    }
  },
  computed: {
    ...mapGetters(['getOrange']),

    ...mapGetters('rh', {
      getRh: 'getRh'
    }),

    ...mapGetters('time', {
      getDataMois: 'getDataMois'
    })
  },
}
</script>

<style scoped>

</style>